.cm-banner-search-container:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
}


.cm-headingpackage {
  width: 60%;
}

.search-bannerpackage.cm-flex {
  align-items: center;
}

.cm-headingpackage h1 {
  font-size: 45px;
  color: #f74e0f;
}

.cm-banner-search-container .cm-fl-search-form {
  position: static;
}

.cm-banner-search-container .cm-fl-search-form:after,
.cm-banner-search-container .cm-fl-search-form:before {
  content: none;
}

.cm-banner-search-container .cm-page-center {
  z-index: 2;
}

.cm-banner-search-container .cm-banner-content h1 {
  font-size: 42px;
  font-weight: 900;
}

.cm-banner-search-container .cm-banner-content p {
  font-size: 18px;
  font-weight: 600;
  margin-top: 20px;
}

.cm-flights-to-item {
  /* clip-path: polygon(50% 0, 100% 30%, 100% 70%, 50% 100%, 0 70%, 0 30%); */
  clip-path: polygon(45% 1.33975%,
      46.5798% 0.60307%,
      48.26352% 0.15192%,
      50% 0%,
      51.73648% 0.15192%,
      53.4202% 0.60307%,
      55% 1.33975%,
      89.64102% 21.33975%,
      91.06889% 22.33956%,
      92.30146% 23.57212%,
      93.30127% 25%,
      94.03794% 26.5798%,
      94.48909% 28.26352%,
      94.64102% 30%,
      94.64102% 70%,
      94.48909% 71.73648%,
      94.03794% 73.4202%,
      93.30127% 75%,
      92.30146% 76.42788%,
      91.06889% 77.66044%,
      89.64102% 78.66025%,
      55% 98.66025%,
      53.4202% 99.39693%,
      51.73648% 99.84808%,
      50% 100%,
      48.26352% 99.84808%,
      46.5798% 99.39693%,
      45% 98.66025%,
      10.35898% 78.66025%,
      8.93111% 77.66044%,
      7.69854% 76.42788%,
      6.69873% 75%,
      5.96206% 73.4202%,
      5.51091% 71.73648%,
      5.35898% 70%,
      5.35898% 30%,
      5.51091% 28.26352%,
      5.96206% 26.5798%,
      6.69873% 25%,
      7.69854% 23.57212%,
      8.93111% 22.33956%,
      10.35898% 21.33975%);
  width: calc(20% - 10px);
  height: 220px;
  background-color: #ccc;
  position: relative;
}

.cm-flights-to-item:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
}

.cm-flights-to-wrapper h4 {
  font-size: 22px;
  padding-bottom: 5px;
  position: relative;
}

.cm-flights-to-item h4:after {
  content: "";
  position: absolute;
  bottom: 0;
  width: 0;
  left: calc(50% - 30px);
  height: 4px;
  border-radius: 15px;
  background-color: var(--secCol2);
  transition: all 300ms ease;
}

.cm-flights-to-item:hover h4:after {
  width: 60px;
}

.cm-flights-to-item .cm-content {
  color: var(--whiteCol);
  width: 100%;
  padding: 5px;
  position: relative;
  z-index: 2;
}

.cm-flights-to-item>i {
  position: absolute;
  top: 10px;
  font-size: 25px;
  width: 50px;
  height: 50px;
  background-color: var(--whiteCol);
  border-radius: 50%;
}

@media screen and (max-width: 1023px) {
  .cm-flights-to-item {
    width: calc(33.33% - 10px);
    height: 270px;
  }

  .cm-banner-search-container .cm-fl-search-form {
    margin: 30px auto 0;
  }

  .cm-banner-search-container .cm-banner-content h1 {
    font-size: 35px;
  }
}

@media screen and (max-width: 767px) {
  .cm-flights-to-item {
    width: calc(50% - 10px);
    height: 250px;
    margin-bottom: 30px;
  }

  .cm-banner-search-container .cm-banner-content h1 {
    font-size: 28px;
  }

  .cm-headingpackage {
    width: 100%;
  }

  .flightSearch {
    width: 100%;
  }

  .cm-headingpackage h1 {
    font-size: 26px;
  }
}

@media screen and (max-width: 480px) {
  .cm-flights-to-item {
    width: 100%;
  }

  .cm-banner-search-container .cm-fl-search-form {
    margin-top: 20px;
  }

  .cm-headingpackage h1 {
    font-size: 20px;
  }
}