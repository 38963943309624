footer {
  background: #0A205C;
}

.footer-text h2 {
  color: #fff;
  font-size: 42px;
  margin-bottom: 20px;
}

.our-destin ul li {
  padding: 0 20px;
}

.our-destin ul li a {
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
}

.footer-middle {
  border-top: 1px solid #fff;
  display: flex;
  flex-wrap: wrap;
  padding: 30px 0;
}

.col-33 {
  width: calc(33.33% - 20px);
  margin: 0 10px;
}

.footer-middle .col-33 h5 {
  color: #fff;
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 10px;
}

.footer-middle .col-33 p {
  color: #fff;
}

.footer-bottom {
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
  padding: 20px 50px;
}

.footer-bottom .col-33 ul {
  display: flex;
  flex-wrap: wrap;
}

.hero {
  width: 300px;
  display: flex;
  flex-wrap: wrap;
  border: 1px solid darkgrey;
}

.our-destin ul {
  display: flex;
  list-style: none;
  justify-content: center;
}

.our-destin ul li {
  padding: 0 20px;
}

.our-destin ul li a {
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
}

.hero input[type="text"] {
  width: calc(100% - 50px);
  border: none;
  outline: none;
  padding: 10px 0;
  padding-left: 10px;
  line-height: 1.5;
  font-size: 16px;
}

.search {
  width: 50px;
  position: relative;
  background-color: #F74E0F;
}

.search input[type="submit"] {
  font-size: 0;
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;
  width: 100%;
  height: 100%;
  z-index: 1;
  position: relative;
}

.search i {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 18px;
  color: #fff;
}

.footer-bottom ul {
  list-style-type: none;
}

.footer-bottom .col-33 ul li {
  padding: 0 10px;
}

.cm-pads {
  padding: 50px 0;
}

@media(max-width:1023px) {
  .our-destin ul li {
    padding: 0 15px;
  }

  .our-destin ul li a {
    font-size: 16px;
  }

  .hero {
    width: 250px;
  }
}

@media(max-width:960px) {
  .cm-fl-search-btn button.cm-btn {
    padding: 15px 15px;
    font-size: 16px;
  }

  .our-destin ul li {
    padding: 0 13px;
  }

  .our-destin ul li a {
    font-size: 15px;
  }
}

@media(max-width:767px) {
  .cm-pads {
    padding: 30px 0;
  }

  .footer-text h2 {
    font-size: 28px;
    margin-bottom: 15px;
  }

  .our-destin ul li {
    padding: 0 10px;
  }

  .our-destin ul li a {
    font-size: 14px;
  }

  .col-33 {
    width: 100%;
    margin: 10px 0px;
  }

  .hero {
    width: 100%;
  }

  .footer-bottom {
    padding: 10px;
  }

  .footer-bottom {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  }

  .footer-bottom .col-33 {
    width: 100%;
  }

  .footer-bottom .col-33 p {
    font-size: 12px;
  }

  .footer-bottom .col-33 ul li {
    padding: 0 7px;
  }
}

@media(max-width:480px) {
  .footer-text h2 {
    font-size: 18px;
    margin-bottom: 10px;
  }

  .our-destin ul {
    display: flex;
    flex-direction: column;
  }

  .our-destin ul li {
    padding: 10px 0px;
  }

  .footer-middle {
    padding: 10px 0;
  }

  .footer-middle .col-33 h5 {
    font-size: 16px;
    margin-bottom: 5px;
  }

  .footer-bottom .col-33 ul li a {
    font-size: 12px;
  }
}